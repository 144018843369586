<template>
    <div>
	<br/>
        <div class="row" v-if="!rule_operator_simple">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <b-form inline>
                                    <router-link v-if="rule_show_order_button" type="button" style="padding-left: 10px" class="btn btn-success" to="/orders/hopshop">{{ $t('orders.nav.new_order_hopshop')}}</router-link>&nbsp;
                                    <router-link v-if="rule_show_order_button" type="button" class="btn" style="background-color: #76367e; padding-left: 10px;color: white" to="/orders/lots">{{ $t('orders.nav.new_order_broadcast')}}</router-link>
                                    <router-link v-if="rule_order_search" type="button" class="btn btn-success" style="margin-left: 10px" to="/orders/search">{{ $t('orders.nav.search_for_orders')}}</router-link>
                                    <b-button v-if="rule_order_create_ticket" variant="danger" style="margin-left: 10px" @click="showModalTicket()">{{ $t('orders.nav.create_ticket')}}</b-button>
                                    <b-form-select v-model="filter" :options="so.option" style="margin-left: 20px" @change="changeFilter()"></b-form-select>
                                    <b-button variant="info" @click="loadData()" style="margin-left: 10px"><b-icon-arrow-clockwise></b-icon-arrow-clockwise></b-button>
                                    <template v-if="load_status == 'idle'">
                                      <b-button v-if="rule_load_sale_channel" variant="primary" style="margin-left: 10px" @click="$bvModal.show('bv-modal-load-sale-channel')">
                                        Загрузка реестра каналов продаж
                                      </b-button>
                                    </template>
                                    <template v-if="load_status == 'processing'">
                                      <b-button v-if="rule_load_sale_channel" variant="info" style="margin-left: 10px" disabled="disabled">
                                        Обработка реестра
                                        <template v-if="processing_sale_channel_count > 0">
                                            <b-badge variant="success" v-b-tooltip.hover title="Обработано заказов">{{processing_sale_channel_count}}</b-badge>
                                        </template>
                                      </b-button>
                                    </template>
                                    <template v-if="load_status == 'error'">
                                      <b-button v-if="rule_load_sale_channel" variant="danger" @click="resetError" style="margin-left: 10px" id="popover-target-load-sale-channel">
                                        Ошибки загрузки
                                      </b-button>
                                      <b-popover target="popover-target-load-sale-channel" triggers="hover" placement="auto">
                                        <template #title>Проблемные заказы:</template>
                                        <table class="table scroll-table-body">
                                          <tr v-for="(item2, index2) in processing_sale_channel_errors">
                                            <td style="font-size: 80%">{{item2.order}}</td>
                                            <td style="font-size: 80%">{{item2.error}}</td>
                                          </tr>
                                        </table>
                                      </b-popover>
                                  </template>
                                  <template v-if="load_status == 'done'">
                                    <b-button v-if="rule_load_sale_channel" variant="success" @click="resetError" style="margin-left: 10px">Реестр успешно загружен
                                      <template v-if="processing_sale_channel_count > 0">
                                        <b-badge variant="success" v-b-tooltip.hover title="Обработано заказов">{{processing_sale_channel_count}}</b-badge>
                                      </template>
                                    </b-button>
                                  </template>
                                </b-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      <br/>
      <div class="row">
        <table class="table" v-if="!rule_operator_simple">
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{{ $t('main.number_order')}}</th>
            <th scope="col">{{ $t('main.date_order')}}</th>
            <th scope="col">{{ $t('main.customer')}}</th>
            <th scope="col">{{ $t('main.phone')}}</th>
            <th scope="col">{{ $t('main.status_order')}}</th>
            <th scope="col">{{ $t('main.operations')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in orders" :key="item.id">
            <td>{{index + 1}}</td>
            <td> {{item.number}}</td>
            <td>{{item.ctime}}</td>
            <td>{{item.client}}</td>
            <td>{{item.phone}}</td>
            <td>{{item.status}}</td>
              <td>
                  <button class="btn btn-sm btn-success"  @click="showOrder(item)" :disabled="!item.edit">
                      <b-icon-pencil-square></b-icon-pencil-square>
                  </button>
                <button class="btn btn-sm btn-info ml-1"  @click="showModalHistory(item.etherid+'-'+item.id)">
                  <b-icon-clock-history></b-icon-clock-history>
                </button>
              </td>
          </tr>
          </tbody>
        </table>
      </div>
      <b-modal id="bv-modal-load-sale-channel">
        <template #modal-title>
          Заливка каналов продаж
        </template>
        <template #modal-footer>
          <b-button v-if="error_msg === ''" class="btn btn-success" @click="submitFile">{{$t('main.download')}}</b-button>
          <b-button class="btn btn-danger" @click="closeForm">{{$t('main.close')}}</b-button>
        </template>
        <div v-if="error_msg === ''" class="d-block text-center">
          <b-form-file id="file-small" size="sm" accept=".xlsx" v-model="file"></b-form-file>
        </div>
        <div v-else>
          <p>{{error_msg}}</p>
        </div>
      </b-modal>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Axios from 'axios';
    import bus from "@/bus";
    import HelpdeskModal from "@/components/helpdeskModal.vue";

export default {
  name: 'Orders',

  components: {
    HelpdeskModal,
  },

  data() {
        return {
            orders: [],
            filter: 'return',
            so: {
                option: [
                    {value: 'return', text: this.$t('orders.variable.return_for_revision') },
                    {value: 'all', text: this.$t('orders.variable.all_orders') }
                ]
            },
            file: [],
            error_msg: '',
            processing_sale_channel_count: 1,
            processing_sale_channel_errors: [],
            load_status: 'idle',
            timer1: null,
        }
    },

  metaInfo() {
    return {
        title: this.$t('orders.title')
    }
  },

  mounted() {
    this.loadData();
    bus.$emit('set-header', this.$t('orders.title'));
  },

  methods: {
    loadData() {                  // загрузка данных с backend
      Axios
          .get(this.prefix_url + '/api/order?ts=' + Date.now()+'&filter='+this.filter)
          .then(response => {
            console.log(response);
            this.orders = response.data.orders;
            this.load_status = response.data.load_status;
            this.processing_sale_channel_count = response.data.processing_sale_channel_count;
            this.processing_sale_channel_errors = response.data.processing_sale_channel_errors;
            if (this.load_status == 'processing' && this.timer1 === null){
              this.timer1 = setInterval(() => {
                Axios
                    .get(this.prefix_url + "/api/order?ts=" + Date.now() + '&type=check_load')
                    .then(res => {
                      if (res.data.success) {
                        if (res.data.status == 'error') {
                          this.load_status = 'error';
                          this.processing_sale_channel_errors = res.data.processing_sale_channel_errors;
                        }else if (res.data.status == 'done') {
                            this.load_status = 'done';
                            this.processing_sale_channel_count = res.data.processing_sale_channel_count;
                        }else {
                          this.load_status = 'idle';
                          this.processing_sale_channel_count = 0;
                        }
                        clearInterval(this.timer1);
                        this.timer1 = null;
                      }else{
                        this.load_status = 'processing';
                        this.processing_sale_channel_count = res.data.processing_sale_channel_count;
                      }
                    });
              }, 10000);
            }
          });
    },

    showOrder(data) {
        bus.$emit('portal:order:open', {
            ether_id: data.etherid,
            order_id: data.id
        });
    },

    showModalTicket () {
      bus.$emit('Tickets::append', {
        type: "service"
      });
    },

    changeFilter(){
        this.loadData();
    },



    showModalHistory(data) {
      bus.$emit('portal:order:history:open', data);
//      this.$bvModal.show('modal-history');
    },

    submitFile(){
      let _this = this;
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('method', 'upload');

      Axios.post(_this.prefix_url + '/api/order?ts='+Date.now(), formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(function(res){
        if (res.data.success) {
          _this.closeForm();
          _this.load_status = 'processing';
          _this.processing_sale_channel_count = 0;
          _this.processing_sale_channel_errors = [];
          if (_this.timer1 == null) {
            _this.timer1 = setInterval(() => {
              Axios
                  .get(_this.prefix_url + "/api/order?ts=" + Date.now() + '&type=check_load')
                  .then(res => {
                    if (res.data.success) {
                      if (res.data.status == 'error') {
                        _this.load_status = 'error';
                        _this.processing_sale_channel_errors = res.data.processing_sale_channel_errors;
                      }else if (res.data.status == 'done') {
                        _this.load_status = 'done';
                        _this.processing_sale_channel_count = res.data.processing_sale_channel_count;
                      }else {
                        _this.load_status = 'idle';
                        _this.processing_sale_channel_count = 0;
                      }
                      clearInterval(_this.timer1);
                      _this.timer1 = null;
                    }else{
                      _this.load_status = 'processing';
                      _this.processing_sale_channel_count = res.data.processing_sale_channel_count;
                    }
                  });
            }, 10000);
          }
        }else{
          _this.error_msg = res.data.message;
          // console.log('FAIL');
        }
      });
    },

    closeForm(){
      this.error_msg = '';
      this.$bvModal.hide('bv-modal-load-sale-channel');
    },

    resetError() {
      Axios
                .post(this.prefix_url + '/api/order/?ts='+Date.now(), {
                  method:     'resetError'
                })
                .then(res => {
                  if (res.data.success) {
                    this.load_status = 'idle';
                    this.processing_sale_channel_count = 0;
                    this.processing_sale_channel_errors = [];
                  }else{
                    bus.$emit('view-message', { message: res.data.message, variant: 'danger' });
                  }
      });
    },
  },

  computed : {
    rule_order_hopshop: function (){return this.$store.getters.getRule('order') },
    rule_order_zargar: function (){return this.$store.getters.getRule('orderzargar') },
    rule_operator_simple: function (){return this.$store.getters.getRule('operator_simple') },
    rule_order_lots: function (){return this.$store.getters.getRule('orderlots') },
    rule_order_search: function (){return this.$store.getters.getRule('orderSearch') },
    rule_order_create_ticket: function (){return this.$store.getters.getRule('ticketCreate') },
    rule_show_order_button: function (){return this.$store.getters.getRule('show_order_button') },
    rule_load_sale_channel: function (){return this.$store.getters.getRule('load_sale_channel') },
    current_phone: {
          get () { return this.$store.getters.getCurrentPhone; },
          set(value) { this.$store.dispatch('setCurrentPhone', value); }
    },
    prefix_url:function () { return process.env.VUE_APP_PREFIX_URL }
  }
}
</script>